import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import { BillingPlan, UserSubscriptionPlan } from 'src/interfaces/Platform.interface'
import { cancelSubscriptionAsync, getUserPlanAsync } from 'src/redux/actions/pricingActions'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'


const CustomTooltip:FC<{msg:string}> = ({msg}) => {
    return (
        <OverlayTrigger overlay={<Tooltip>{msg}</Tooltip>} >
            <i className="bi bi-info-circle text-dark"></i>
        </OverlayTrigger>
    )
}

const BillingPlanDetail:FC = () => {

    const [activePlanDetail, setActivePlanDetail] = useState<UserSubscriptionPlan|null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const {fetchedUserAccountLoading, fetchedUserAccountData} = useAppSelector(
        (state) => state.userAccountData
      )
    const [visibleSubscription, setVisibleSubscription] = useState(false)
    const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
    const [isCancelSubscriptionConfirm, setIsCancelSubscriptionConfirm] = useState<boolean>(false)
    const [isCanceling, setIsCanceling] = useState<boolean>(false)
    const [copySubscriptionMsg, setCopySubscriptionMsg] = useState('Copy')
    const dispatch = useAppDispatch()
    const { errorToast,successToast,warningToast } = useAppToast()

    useEffect(() => {
        setLoading(true)
        dispatch(getUserPlanAsync()).then((response) => {
            console.log('list of  userplan', response)
            if(response?.payload?.data?.billing_plan){
              setActivePlanDetail(response.payload?.data)
            }
            if(response.payload?.status===204){
                //will not get user billing plan
                setActivePlanDetail(null)
                // warningToast(response.payload?.data?.error)
              }
              if(response.payload?.status===500){
                warningToast(response.payload?.data?.error)
              }
            setLoading(false)
          })
          .catch(()=>{
            setLoading(false)
          })
    }, [])
    

    
  return (
    <div className='row justify-content-center' >
        <div className="card card-custom bg-white shadow border-0">            
            <div className="card-header bg-white ">
                <div className="card-title"><h3>Billing Details</h3></div>
                <div className='card-toolbar' >
                    {
                        ['SHOPIFY', 'WOOCOMMERCE','ECWID' ].includes(fetchedUserAccountData?.source!) ? 
                            <Link to={'/pricing'} className='btn btn-sm btn-primary fw-bold align-items-center' >Manage Plan<i className="bi bi-box-arrow-up-right ms-1 fw-bold"></i></Link>
                        : null
                    }
                </div>
            </div>
           { loading || fetchedUserAccountLoading ? <Loader/> :  
               activePlanDetail ?
                <div className="card-body">
                    <div>
                        <h4>Current Plan</h4> 
                        <div className='separator my-2' ></div>
                        <div className="row mt-4">
                            <div className="col">
                                <p className='fs-5 fw-bold text-gray-600 mb-0' >Active Plan</p>
                            </div>
                            <div className="col">
                                <p className='fs-5 fw-bold' >
                                    {activePlanDetail?.billing_plan.display_name}
                                </p>
                            </div>
                        </div>
                      {/* { activePlanDetail?.subscription_id ?  
                        <div className="row my-4 mt-2">
                            <div className="col">
                                <p className='fs-5 fw-bold text-gray-600 mb-0' >Subscription Id</p>
                            </div>
                            <div className="col d-flex flex-row align-items-center">
                                    <OverlayTrigger overlay={<Tooltip>{copySubscriptionMsg}</Tooltip>} >
                                        <p className='fs-5 cursor-pointer text-hover-primary fw-bold mb-0' unselectable='on' onClick={()=>{
                                            setCopySubscriptionMsg('Copied')
                                            if(activePlanDetail?.subscription_id){
                                                navigator.clipboard.writeText(activePlanDetail?.subscription_id)
                                            }
                                        }} onMouseLeave={()=>{
                                            setCopySubscriptionMsg('Copy')
                                        }}  >
                                            {activePlanDetail?.subscription_id} 
                                        </p>
                                    </OverlayTrigger>
                            </div>
                        </div> : null } */}
                        <div className="row mt-2">
                            <div className="col">
                                <p className='fs-5 fw-bold text-gray-600 mb-0' >Billing Price</p>
                            </div>
                            <div className="col">
                                <span className='badge badge-light-success text-success rounded-pill fs-6 px-4 py-2' > { (activePlanDetail?.billing_plan?.showcase_price?.value)?.toString() + " " + activePlanDetail?.billing_plan.showcase_price.currency_iso + " / " + (activePlanDetail?.billing_plan.billing_frequency==='ANNUAL' ? 'Yearly' : 'Monthly') }</span>
                            </div>
                        </div>
                        {/* <div className="row mt-4">
                            <div className="col">
                                <p className='fs-5 fw-bold text-gray-600 mb-0' >Platform Uses Limit</p>
                            </div>
                            <div className="col">
                            <p className='fs-4 fw-bold'>{activePlanDetail?.billing_plan.platform_limit}</p>
                            </div>
                        </div> */}
                        <div className="row mt-4">
                            <div className="col">
                                <p className='fs-5 fw-bold text-gray-600 mb-0' >SKUs Limit </p>
                            </div>
                            <div className="col">
                            <p className='fs-5 fw-bold'>{activePlanDetail?.billing_plan.product_limit}</p>
                                {/* <small className='text-danger fs-6' >( 100 creation left )</small> */}
                            </div>
                        </div>

                        { activePlanDetail?.activated_on ?
                            <div className="row mt-2">
                                <div className="col">
                                    <p className='fs-5 fw-bold text-gray-600 mb-0' >Subscribed On</p>
                                </div>
                                <div className="col">
                                <p className='fs-5 fw-bold '>{ activePlanDetail?.activated_on ? moment(activePlanDetail?.activated_on).utc(true).format('YY-MMM-DD hh:mm A') : '-' }</p>
                                </div>
                            </div> 
                        : null }
 
                        { activePlanDetail?.valid_till ? 
                            <div className="row mt-2">
                                <div className="col">
                                    <p className='fs-5 fw-bold text-gray-600 mb-0' >Valid Till </p>
                                </div>
                                <div className="col">
                                <p className='fs-5 fw-bold '>{activePlanDetail?.valid_till ? moment(activePlanDetail.valid_till).utc(true).format('YY-MMM-DD hh:mm A') : '-' }</p>
                                </div>
                            </div> 
                        : null }

                        { activePlanDetail?.billing_plan.billing_frequency!=='FREE_FOREVER' && activePlanDetail?.isActive ? (
                            <>
                                <div className='d-flex justify-content-end py-3 px-2'>
                                    <button 
                                        onClick={() => {
                                        setIsCancelSubscriptionConfirm(true)
                                        }}
                                    type='submit' className='btn btn-sm btn-danger fs-6 fw-bold me-2'>
                                    Cancel Subscription
                                    </button>
                                </div>
                            </>
                        ) 
                        : null}
                    </div>
                </div>
                : <div className="card-body">
                    <h4>No Plans Found</h4>
                </div>
            }
        </div>
        <CommonAlertModal
            title={
            fetchedUserAccountData?.source === 'WIX'
                ? `You have to cancel your current plan from Wix admin portal`
                : `Are you sure you want to cancel the Subscribed plan? The Plan will be downgraded to Free Forever plan.`
            }
            subtitle=''
            successDisabled={isCanceling}
            successBtnTitle={isCanceling ? 'Updating...' : 'Yes Confirm'}
            isTermsVisible={false}
            isVisible={isCancelSubscriptionConfirm}
            onCancel={() => {
            setIsCancelSubscriptionConfirm(false)
            }}
            onSuccess={() => {
                if(fetchedUserAccountData?.source === 'WIX'){
                    setIsCancelSubscriptionConfirm(false)
                }else{
                    setIsCanceling(true)
                    dispatch(
                        cancelSubscriptionAsync({
                        subscription_id: activePlanDetail?.subscription_id,
                        platform_id: mainStoreData?.id,
                        })
                    ).then((response) => {
                        if (response?.payload?.status_code === 201 && response?.payload?.data) {
                        successToast(response?.payload?.message)
                        setIsCanceling(false)
                        setIsCancelSubscriptionConfirm(false)
                        dispatch(getUserPlanAsync()).then((response) => {
                            console.log('list of  userplan', response)
                            setActivePlanDetail(response.payload?.data)
                        })
                        } else {
                        errorToast(response?.payload?.message)
                        setIsCanceling(false)
                        setIsCancelSubscriptionConfirm(false)
                        dispatch(getUserPlanAsync()).then((response) => {
                            console.log('list of  userplan', response)
                            setActivePlanDetail(response.payload?.data)
                        })
                        }
                    })
                    }}
                }
      />
    </div>
  )
}

export default BillingPlanDetail