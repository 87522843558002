import React, {useState, useMemo, FC, useEffect, createRef, Fragment} from 'react'
import DataTable from 'react-data-table-component'
import 'bootstrap/dist/css/bootstrap.min.css'
import {Link, useSearchParams} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight, faArrowsRotate, faCog, faFile, faLink, faRotate} from '@fortawesome/free-solid-svg-icons'
import {PlatformDataType, PlatformSettingsDataType} from 'src/interfaces/Platform.interface'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {useRef} from 'react'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import {
  createPlatformSettingAsync,
  delistVariantsAsync,
  refreshPlatformDataAsync,
  retrivePlatformDataAsync,
} from 'src/redux/actions/platformActions'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'
import {
  getAllProductsVarientsFromChannelAsync,
  getAllProductsVarientsFromMainChannelAsync,
  linkProductVarientAsync,
  productVarientResyncAsync,
} from 'src/redux/actions/productActions'
import LinkButton from '../LinkButton'
import {KTSVG} from 'src/marketConnect/helpers'
import {Dropdown, Modal, OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap'
import CSVUploadModal from '../CSVUploadModal'
import Loader from 'src/utils/Loader'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'
import {isActiveBoolean} from 'src/redux/actions/changeisActiveAction'
import {setProductTablePaginationData} from 'src/redux/slices/productSlice'
import StatusButton from 'src/app/pages/orders/StatusButton'
import useAppToast from 'src/hooks/useAppToast'

const CommonColumn: FC<{rowData: string}> = ({rowData}) => {
  return (
    <>
      <p>{rowData}</p>
    </>
  )
}

const ProductNameWithLink: FC<{rowData: any}> = ({rowData}) => {
  return (
    <div>
      <Link
        className='fw-bold text-info mb-0 text-decoration-none'
        to={`/product-variant-detail/${rowData?.id}/platform/${rowData?.platform_product?.platform}/product/${rowData?.platform_product?.id}`}
      >
        <h5 className='text-start mb-0'>{rowData?.platform_product?.name + ' '}</h5>
      </Link>
      <small className='fw-bold'>
        {rowData?.name == 'Default Title' ? ' ' : 'Variant : ' + rowData?.name}
      </small>
    </div>
  )
}

const ProductsTable: FC = () => {
  const dispatch = useAppDispatch()
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [mainStore, setMainStore] = useState(false)
  const [tooltipClosed, setTooltipClosed] = useState(false)
  const [displayName, setDisplayName] = useState<any>()
  const [platformData, setPlatformData] = useState<PlatformDataType|null>(null)
  const [currentPlatformId, setCurrentPlatformId] = useState<any>()
  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 10,
    search_string: '',
    sort_by: '',
    sort_order: '',
  })
  const itemsPerPage = 10 // Adjust as needed
  const [linkingCompleted, setLinkingCompleted] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [matchedParentProduct, setMatchedParentProduct] = useState<any | null>(null)
  const [dataFetched, setDataFetched] = useState(false)
  const [clickedRowIndex, setClickedRowIndex] = useState(null)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [csvFile, setCSVFile] = useState<File | null>(null)
  const [platformModalId, setPlatformModalId] = useState<string>('')
  const [search_string, setSearch_string] = useState('')
  const [openConnectConfirmation, setOpenConnectConfirmation] = useState(false)
  const {connectedPlatformData} = useAppSelector((state) => state.connectedPlatform)
  const [currentId, setCurrentId] = useState<string | null>(null)
  const [selectSyncStatus, setSelectSyncStatus] = useState('')
  const {successToast, errorToast} = useAppToast()
  const [paginationMainData, setPaginationMainData] = useState({
    page: 1,
    limit: 10,
    search_string: '',
  })
  const [showRefreshModal, setShowRefreshModal] = useState<boolean>(false)
  const [refreshPlatformId, setRefreshPlatformId] = useState<number|null>(null)
  const [refreshing, setRefreshing] = useState(false)
  const [selectedItemForActions, setSelectedItemForActions] = useState<number[]>([])
  const [opnDelistModal, setOpnDelistModal] = useState(false)
  const [isDelistIng, setIsDelistIng] = useState(false)
  const [selectedAction, setSelectedAction] = useState('')
  const {id} = useParams()

  // Handle click event from LinkButton
  const ResyncButton = ({productId}) => {
    const handleResyncClick = async () => {
      console.log('Resync clicked for PRODUCT:', productId)

      // Dispatch your API call action here, passing the orderId

      try {
        // Dispatch your API call action here, passing the orderId
        dispatch(productVarientResyncAsync({failed_variant_ids: [productId]})).then((response) => {
          if (id) {
            dispatch(
              getAllProductsVarientsFromChannelAsync({
                id: id,
                paginationdata: {...productTablePaginationData},
              })
            )
            // Show toast notification after successful dispatch
          }
          successToast('Failed Product resyncing started, status changed to Pending')
        })
      } catch (error) {
        console.error('Error during order resync:', error)
        toast.error('Order resync failed. Please try again.')
      }
    }

    return (
      <Fragment>
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={`tooltip-${productId}`}>Click to resync the product variant</Tooltip>
          }
        >
          <Button
            className='ms-3 '
            variant='link'
            style={{padding: '5px 10px', cursor: 'pointer'}}
            onClick={handleResyncClick}
          >
            <FontAwesomeIcon icon={faRotate} color='red' size='lg' />
          </Button>
        </OverlayTrigger>
      </Fragment>
    )
  }

  const handleIconClick = (rowIndex) => {
    // Use the rowIndex here
    setShowModal(true)
    setClickedRowIndex(rowIndex)
    console.log('Clicked on row index:', rowIndex)
  }

  const {
    loadingProductVarientsFromChannel,
    productVarientsOfSingleChannel,
    productVarientsCountOfSingleChannel,
  } = useAppSelector((state) => state.productVarientsOfSingleChannel)

  const {productTablePaginationData, reset} = useAppSelector(
    (state) => state.productsOfSingleChannel
  )

  const productVarientsFromMainChannel = useAppSelector((state) => state.productVarientFromMain)
  const fetchedConnectedPlatformData = useAppSelector(
    (state) => state.fetchConnectedPlatform.fetchedConnectedPlatformData
  )
  const mainStoreId = useAppSelector((state) => state.fetchConnectedPlatform.mainStoreData?.id)

  useEffect(() => {
    if (mainStoreId) {
      try {
        dispatch(
          getAllProductsVarientsFromMainChannelAsync({
            id: String(mainStoreId),
            paginationdata: paginationMainData,
          })
        )
      } catch (err) {
        console.error('Error fetching platform data:', err)
      }
    }
  }, [mainStoreId, paginationMainData])

  const handlePagination = (pageNumber) => {
    setPaginationMainData((pre) => {
      return {...pre, page: pageNumber}
    })
  }
  // const handleItemsPerPageChange = (perPage) => {
  //   setPaginationMainData((prevPaginationData) => {
  //     return {...prevPaginationData, limit: perPage}
  //   })
  // }

  const clickedProductVariant: any =
    clickedRowIndex !== null &&
    clickedRowIndex >= 0 &&
    clickedRowIndex < productVarientsOfSingleChannel.length
      ? productVarientsOfSingleChannel[clickedRowIndex]
      : null

  // useEffect(() => {
  //   console.log('clickedProductVariant', clickedProductVariant)
  // }, [clickedProductVariant])

  const {
    name,
    sku,
    platform_product: {name: platformProductName = '', image_set: [productImage] = []} = {},
  } = clickedProductVariant || {}

  const handleClose = () => {
    setShowModal(false)
    setMatchedParentProduct(null) // Reset matched product details when modal is closed
    setSearchTerm('')
    setOpenConnectConfirmation(false)
    setPaginationMainData({
      limit: 10,
      page: 1,
      search_string: '',
    })
  }

  const handleLinkProductVariant = () => {
    console.log('matchedParentProduct data i want to see', matchedParentProduct)
    if (matchedParentProduct && clickedProductVariant && clickedProductVariant.id) {
      setLinkingCompleted(true) // Set linking completion state to true
      dispatch(
        linkProductVarientAsync({
          variant_id: clickedProductVariant?.id, // Optional chaining to safely access id
          parent_variant_id: matchedParentProduct?.id,
        })
      )
        .then((response) => {
          // toast.success(response?.payload?.message) // Show success toast
          toast.success('Product Listing Linked Successfully')
          handleClose() // Close the modal
          setLinkingCompleted(false) // Set linking completion state to true
        })
        .catch((er) => {
          setLinkingCompleted(false)
        })
    } else {
      console.log('Please select a product before linking.')
    }
  }

  const handleCloseTooltip = () => {
    //
    setTooltipClosed(false)
  }

  useEffect(() => {
    const getProductVarientsOfChannel = () => {
      try {
        if (id) {
          setCurrentId(id)
          // alert(paginationData.page)
          dispatch(
            getAllProductsVarientsFromChannelAsync({
              id: id,
              paginationdata: {...productTablePaginationData},
            })
          )
        }
      } catch (err) {
        console.error('Error fetching platform data:', err)
      }
    }
    // Call the fetchData function
    getProductVarientsOfChannel()
  }, [id, productTablePaginationData, linkingCompleted])

 
  useEffect(() => {
    const mainStorePlatform1 = fetchedConnectedPlatformData.find(
      (platform) => platform.id === Number(id)
    )
    if (mainStorePlatform1) {
      setDisplayName(mainStorePlatform1?.code) // Assuming displayName property exists in your platform object
      setPlatformData(mainStorePlatform1)
    }

    const mainStorePlatform = fetchedConnectedPlatformData.find(
      (platform) => platform.id === Number(id) && platform.isMainStore
    )
    const isMainStore = mainStorePlatform ? true : false
    setMainStore(isMainStore)
  }, [id, fetchedConnectedPlatformData])


  const columns1 = [
    {
      name: 'Product Name',
      cell: (row) => <ProductNameWithLink rowData={row} />,
      sortable: true,
      grow: 2,
      width: '220px',
      sortField: 'name',
      left: true,
      style: {paddingLeft: '20px'}, // Add left padding to adjust starting position
      headerStyle: {textAlign: 'center'},
      // cellStyle: {textAlign: 'right'}, // Align cell content to the left
    },
    {
      name: 'Barcode',
      cell: (row) => <CommonColumn rowData={row.barcode} />,
      // sortable: true,
      width: '160px',
      center: true,
    },

    // {
    //   name: 'Status',
    //   cell: (row) => <p className='badge badge-success'>{row?.platform_product?.api_status}</p>,
    //   // sortable: true,
    //   right: true,
    //   // width: '100px',
    //   center: true,
    // },
    {
      name: 'Status',
      cell: (row) => (
        <p
          className='badge badge-success'
          style={{
            // backgroundColor:
            // syncStatus === 'ERROR' ? 'red' :
            // syncStatus === 'PENDING' ? '#FFD700' :
            // syncStatus === 'SYNCED' ? 'green' :
            // syncStatus === 'SUCCESS' ? 'green' :
            // syncStatus === 'ERROR_UNLINKED' ? 'red' :
            // '#FFFF99', // Default color for unknown statuses
            color: 'white',
            padding: '5px 10px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            width: '130px',
          }}
        >
          {row?.platform_product?.api_status
            ? row?.platform_product?.api_status[0].toUpperCase() +
              row?.platform_product?.api_status.slice(1)
            : '-'}
        </p>
      ),
      // sortable: true,
      right: true,
      // width: '100px',
      center: true,
    },

    {
      name: 'Store SKU',
      cell: (row) => <CommonColumn rowData={row.sku} />,
      // sortable: true,
      // width: '160px',
    },
    {
      name: 'Inventory Level',
      cell: (row) => <CommonColumn rowData={row?.quantity} />,
      // sortable: true,
      right: true,
      // width: '120px',
      center: true,
    },
    {
      name: 'Price',
      cell: (row) => <CommonColumn rowData={row?.price?.value} />,
      // sortable: true,
      right: true,
      // width: '100px',
      center: true,
      sortField: 'price',
    },
    // {
    //   name: 'Price Tmpl',
    //   cell: (row) => <CommonColumn rowData={'row.priceTmpl'} />,
    //   sortable: true,
    //   width: '160px',
    // },
    // {
    //   name: 'Title & Des Tmpl',
    //   cell: (row) => <CommonColumn rowData={'row.titleDesTmpl'} />,
    //   sortable: true,
    //   width: '160px',
    // },
    // {
    //   name: 'Category Tmpl',
    //   cell: (row) => <CommonColumn rowData={'row.categoryTmpl'} />,
    //   sortable: true,
    //   width: '160px',
    // },
  ]

  const columnsRange = [
    {
      name: (
        <span
          className='link-status-icon MuiSvgIcon-root MuiSvgIcon-colorPrimary'
          style={{marginRight: '5px'}}
          title='Indicates whether the listing is linked to a product in the product catalog'
        >
          Linking Status
        </span>
      ),
      cell: (row, rowIndex) => (
        <div>
          <LinkButton
            rowData={row}
            parent={row?.parent}
            rowIndex={rowIndex}
            onCloseTooltip={handleCloseTooltip}
            onClick={handleIconClick}
            // products={productsOfSingleChannel}
            productVarients={productVarientsOfSingleChannel}
            linkingCompleted={linkingCompleted}
          />
        </div>
      ),
      width: '150px',
      center: true,
    },

    {
      name: 'Product Name',
      cell: (row) => <ProductNameWithLink rowData={row} />,
      sortable: true,
      grow: 2,
      width: '240px',
      sortField: 'name',
      left: true, // Align header text to the center
      // Add left padding to adjust starting position
      headerStyle: {paddingLeft: '50px'}, // Align header text to the center
    },

    {
      name: 'Barcode',
      cell: (row) => <CommonColumn rowData={row?.barcode} />,
      width: '160px',
    },

    // {
    //   name: 'Status',
    //   cell: (row) => (
    //     <p className='badge badge-success'>
    //       {row?.platform_product?.api_status
    //         ? row?.platform_product?.api_status[0].toUpperCase() +
    //           row?.platform_product?.api_status.slice(1)
    //         : '-'}
    //     </p>
    //   ),
    //   // sortable: true,
    //   right: true,
    //   // width: '100px',
    //   center: true,
    // },

    {
      name: 'Sync Status',
      cell: (row) =>
        row ? (
          row?.sync_status === 'PROCESSING'|| 
          row?.sync_status === 'ERROR' ||
          row?.sync_status === 'ERROR_UNLINKED'  ? (
            <div className='d-flex '>
              <StatusButton syncStatus={row?.sync_status} syncMessage={row?.sync_message} />

              <ResyncButton
                productId={row.id}
                // Implement the onResyncClick function to handle resync logic
              />
            </div>
          ) : (
            <StatusButton syncStatus={row?.sync_status} syncMessage={row?.sync_message} />
          )
        ) : null,
      // sortable: true,
      right: true,
      center: true,
      // width: process.env.REACT_APP_ENV === 'staging' ? '200px' : '0px', // Set width conditionally
      // style: {display: process.env.REACT_APP_ENV === 'staging' ? 'block' : 'none'}, // Set display style conditionally
    },

    {
      name: 'Store SKU',
      cell: (row) => <CommonColumn rowData={row?.sku} />,
      // sortable: true,
      width: '160px',
      right: true,
      center: true,
    },
    {
      name: 'Inventory Level',
      cell: (row) => <CommonColumn rowData={row?.quantity} />,
      // sortable: true,
      right: true,
      // width: '120px',
      center: true,
    },
    {
      name: 'Price',
      cell: (row) => <CommonColumn rowData={row?.price?.value} />,
      // sortable: true,
      right: true,
      width: '100px',
      center: true,
      sortField: 'price',
    },
  ]
  
  const columns = mainStore ? columns1 : columnsRange

  // Function to open the CSV upload modal
  const handleOpenUploadModal = () => {
    setShowUploadModal(true)

    if (id) {
      setPlatformModalId(id)
    }
  }

  const handleCloseUploadModal = () => {
    setShowUploadModal(false)
  }

  const handleSort = (ev, sortDirection) => {
    console.log('ev', ev)
    dispatch(
      setProductTablePaginationData({
        sort_by: ev.sortField,
        sort_order: (sortDirection + '').toUpperCase(),
        sync_status: selectSyncStatus
      })
    )
    // setPaginationData((pre) => {
    //   return {...pre, sort_by: ev.sortField, sort_order: (sortDirection + '').toUpperCase()}
    // })
  }



  // const handleUploadCSVFile = (csvFile: File | null) => {
  //   try {
  //     if (csvFile) {
  //       alert(csvFile.name); // Confirm that it's a file object
  //       console.log("id to upload:", String(id));
  //       dispatch(uploadCsvAsync({ id: String(id), csvFile }));
  //       setCSVFile(null); // Reset CSV file state
  //       handleCloseUploadModal(); // Close the upload modal
  //     }
  //   } catch (error) {
  //     console.error("Error uploading CSV file:", error);
  //     // Handle error (e.g., show error message)
  //   }
  // };
 
 

  const handleResetFilters = () => {
    dispatch(
      setProductTablePaginationData({
        page: 1,
        sync_status: '',
        search_string:'',
      
      })
    )
    setSelectSyncStatus('')
    setSearch_string('')
  }


   

  return (
    <>
      <div className='product-table-container  mb-3'>
        <div className='d-flex flex-row flex-wrap justify-content-between mb-3'>
          <div className='d-flex flex-row flex-wrap'>
            <div className='d-flex align-items-center'>
              <span className='fw-bold text-lg' style={{fontSize: '16px'}}>
                {mainStore ? 'Primary Store Product' : 'Secondary Store Product '}
              </span>
              <span className='ms-2 fw-bold text-lg' style={{fontSize: '16px'}}>
                - {displayName}
              </span>
            </div>
          </div>
          <div>
            {/* <div className='ms-2  pe-2 mb-3'>
              {displayName === 'The Range' && (
                <button
                  className='btn  me-4 ms-10 btn-primary btn-sm d-inline-flex'
                  onClick={handleOpenUploadModal}
                >
                  <div className='me-2' style={{fontSize: '14px'}}>
                    <FontAwesomeIcon icon={faFile} className='svg-icon-muted svg-icon-2hx' />
                  </div>
                  <div style={{fontSize: '14px'}}>Upload Product List</div>
                </button>
              )}

              <button
                className='btn btn-primary btn-sm d-inline-flex'
                onClick={handleOpenSettingsModal}
              >
                <div className='mt-1 me-2'>
                  <FontAwesomeIcon
                    icon={faCog}
                    className='svg-icon-muted svg-icon-2hx'
                    style={{fontSize: '14px'}}
                  />
                </div>
                <div style={{fontSize: '14px'}}>Settings</div>
              </button>
            </div> */}
            <div className='d-flex justify-content-sm-end ms-2 pe-2 mb-3 mt-2'>
              {['THERANGEPLUS','DSCO','AMAZONVENDOR','WAYFAIR','ABEBOOKS'].includes(displayName) && (
                <button
                  className='btn btn-primary btn-sm d-inline-flex align-items-center me-2'
                  onClick={handleOpenUploadModal}
                  style={{fontSize: '14px', padding: '0.25rem 0.5rem'}}
                >
                  <div className='me-2' style={{fontSize: '14px'}}>
                    <FontAwesomeIcon icon={faFile} className='svg-icon-muted svg-icon-2hx' />
                  </div>
                  <div style={{fontSize: '14px'}}>Upload Product List</div>
                </button>
              )}

              {mainStore ?
                    <Link
                      to={`/primary-platform-setting/${id}`}
                      className='btn btn-primary btn-sm d-inline-flex align-items-center '
                      // onClick={handleOpenSettingsModal}
                      style={{fontSize: '14px', padding: '0.25rem 0.5rem'}}
                    >
                      <div className='me-2' style={{fontSize: '14px'}}>
                        <FontAwesomeIcon icon={faCog} className='svg-icon-muted svg-icon-2hx' />
                      </div>
                      <div style={{fontSize: '14px'}}>Settings</div>
                    </Link> :        <Link
                      to={`/platform-setting/${id}`}
                      className='btn btn-primary btn-sm d-inline-flex align-items-center '
                      // onClick={handleOpenSettingsModal}
                      style={{fontSize: '14px', padding: '0.25rem 0.5rem'}}
                    >
                      <div className='me-2' style={{fontSize: '14px'}}>
                        <FontAwesomeIcon icon={faCog} className='svg-icon-muted svg-icon-2hx' />
                      </div>
                      <div style={{fontSize: '14px'}}>Settings</div>
                    </Link>}

                    <button className="btn btn-sm btn-primary d-inline-flex ms-2 me-sm-4" 
                    style={{fontSize: '14px'}} onClick={()=>{
                setShowRefreshModal(true)
                setRefreshPlatformId(Number(id))
                
              }} >
                 <div className='me-2' style={{fontSize: '14px'}}>
                 <FontAwesomeIcon icon={faArrowsRotate} className='svg-icon-muted svg-icon-2hx' />
                  </div>
                  <div style={{fontSize: '14px'}}>Refresh Data</div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='card card-flush'>
        <div className='card-header'>
          <div className='card-title'>
            <input
              className='form-control mr-sm-2 input-sm '
              type='search'
              placeholder='Search'
              aria-label='Search'
              onChange={(ev) => {
                setSearch_string(ev.target.value)
              }}
              onKeyDown={(ev) => {
                console.log('ev.key', ev.key)
                if (ev.key == 'Enter') {
                  dispatch(
                    setProductTablePaginationData({
                      search_string: search_string,
                      page: 1,
                    })
                  )
                  // setPaginationData((pre) => {
                  //   return {...pre, search_string: search_string, page: 1}
                  // })
                }
              }}
              value={search_string}
            />
          </div>
          <div className='card-toolbar'>
            <div className='d-flex flex-md-row flex-column justify-content-md-end'>
              {
                !mainStore && (
                  <select
                  disabled={!selectedItemForActions.length}
                  className='form-select me-2'
                  aria-label='Select Action'
                  value={selectedAction}
                  onChange={(ev) => {
                    setSelectedAction(ev.target.value)
                    if(ev.target.value=='DELIST'){
                      setOpnDelistModal(true)
                    }else{
                      setOpnDelistModal(false)
                    }
                  }}
                >
                  <option value={''}>Action</option>
                  <option value={'DELIST'}>Delist</option>
                </select>
                )
              }
            {!mainStore && (
                <select
                  className='form-select me-2 w-200px'
                  aria-label='Select Sync Status'
                  value={selectSyncStatus}
                  onChange={(ev) => {
                    setSelectSyncStatus(ev.target.value)
                    dispatch(
                      setProductTablePaginationData({
                        page: 1,
                        sync_status: ev.target.value,
                      })
                    )
                  }}
                >
                  <option value={''}> Product Status</option>
                  <option value={'LINKED'}>LINKED</option>
                  <option value={'PENDING'}>PENDING</option>
                  <option value={'CREATED'}>CREATED</option>
                  <option value={'ERROR'}>ERROR</option>
                  <option value={'PROCESSING'}>PROCESSING</option>
                </select>
              )}
              <div className='mx-md-2 my-1 my-md-0'>
                <button
                  className='btn  btn-sm btn-primary w-100px fw-bold py-2'
                  onClick={handleResetFilters}
                >
                  Reset Filters
                </button>
              </div>
            </div>
          </div>
        </div>

        <DataTable
          key={'producttable' + reset}
          fixedHeader={true}
          columns={columns}
          progressPending={loadingProductVarientsFromChannel}
          pagination
          paginationServer
          selectableRowsNoSelectAll
          onSort={handleSort}
          selectableRows={!mainStore}
          onSelectedRowsChange={({allSelected,selectedCount,selectedRows})=>{
            setSelectedItemForActions(selectedRows.map((item:any)=>item?.id))
          }}
          selectableRowsHighlight={true}
          paginationTotalRows={productVarientsCountOfSingleChannel}
          data={productVarientsOfSingleChannel}
          onChangePage={(pageNumber, totalRows) => {
            dispatch(setProductTablePaginationData({page: pageNumber}))
            // setPaginationData((pre) => {
            //   return {...pre, page: pageNumber}
            // })
          }}
          onChangeRowsPerPage={(currentPage) => {
            dispatch(setProductTablePaginationData({limit: currentPage}))
            // setPaginationData((pre) => {

            //   return {...pre, limit: currentPage}
            // })
          }}
          progressComponent={<Loader />}
          // onChangePage={(pageNumber, totalRows) => {
          //   dispatch(getProductsListAsync({id: pageNumber.toString(), subparams:{page:pageNumber} }))
          // }}

          // subHeader
          // subHeaderComponent={subHeaderComponentMemo}
          customStyles={{
            table: {
              style: {
                height: '50vh',
              },
            },
            headCells: {
              style: {fontWeight: 'bold', fontSize: '14px'},
            },
            cells: {
              style: {
                padding: '10px',
                borderBottom: '1px solid gainsboro',
                fontWeight: 'bold',
              },
            },
          }}
        />
      </div>
      <div>
        
        <Modal size='lg' show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Find Matching Product</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{overflowY: 'scroll', minHeight: '300px', maxHeight: '500px'}}>
            <div>
              <h5>
                Search for an existing product to match to this listing. Enter Product Name or SKU
                Store.
              </h5>
            </div>

            <div>
              <div className='d-flex align-items-center'>
                <span className='symbol-label m-3' style={{backgroundImage: 'none'}}>
                  <img
                    src={
                      productImage
                        ? productImage.image_url
                        : 'https://appconnect-images.s3.us-east-2.amazonaws.com/No_Image_Available.jpg'
                    }
                    alt='Product Image'
                    style={{width: '50px', height: '50px'}}
                  />
                </span>
                <div>
                  <div>
                    <h4>
                      {platformProductName +
                        '  - ' +
                        (`${name}` == 'Default Title' ? ' ' : `${name}`)}{' '}
                    </h4>

                    <h5>Variant_Sku: {`${sku}`}</h5>
                  </div>
                </div>
              </div>
            </div>
            <input
              type='text'
              placeholder='Search the product from ProductName or Sku'
              value={searchTerm}
              onChange={(ev) => {
                setSearchTerm(ev.target.value)
              }}
              onKeyDown={(ev) => {
                if (ev.key == 'Enter') {
                  setPaginationMainData((pre) => {
                    return {...pre, search_string: searchTerm}
                  })
                }
              }}
              style={{marginBottom: '10px', width: '100%'}}
            />
            {productVarientsFromMainChannel.loadingProductVarientFromMainChannel ? (
              <Loader />
            ) : productVarientsFromMainChannel.productVarientFromMainChannel.length ? (
              productVarientsFromMainChannel.productVarientFromMainChannel.map(
                (product: any, index) => (
                  <div key={index} className='product-item' style={{cursor: 'pointer'}}>
                    <div className='border-bottom my-2 border-2'></div>

                    <div className='d-flex align-items-center justify-content-start'>
                      <div className='m-2 me-4'>
                        <span className='symbol-label' style={{backgroundImage: 'none'}}>
                          {product.platform_product &&
                          product.platform_product.image_set.length > 0 ? (
                            <img
                              src={product.platform_product.image_set[0].image_url}
                              alt={`Product Image ${index}`}
                              style={{width: '50px', height: '50px'}}
                            />
                          ) : (
                            <img
                              src='https://appconnect-images.s3.us-east-2.amazonaws.com/No_Image_Available.jpg'
                              alt='No Image Available'
                              style={{width: '50px', height: '50px'}}
                            />
                          )}
                        </span>
                      </div>
                      <div className='d-flex justify-content-between w-100 align-items-center'>
                        <div className='me-4 text-start'>
                          <div>
                            <h4>
                              {product?.platform_product?.name +
                                '  - ' +
                                (product?.name == 'Default Title' ? ' ' : product?.name)}{' '}
                            </h4>
                            <h4>{product?.sku}</h4>
                          </div>
                        </div>
                        <button
                          className={`m-4 text-end btn btn-primary btn-sm d-flex align-items-center justify-content-center `}
                          style={{fontSize: '10px'}}
                          onClick={() => {
                            // if (
                            //   matchedParentProduct &&
                            //   matchedParentProduct?.platform_product?.id === product?.platform_product?.id
                            // ) {
                            //   // If the clicked product is already selected, deselect it
                            //   console.log('Deselecting product:', product)
                            //   setMatchedParentProduct(null)
                            // } else {
                            //   // Otherwise, select the clicked product
                            //   console.log('Selecting product:', product)
                            // }
                            setMatchedParentProduct(product)
                            setOpenConnectConfirmation(true)
                          }}
                        >
                          {matchedParentProduct && matchedParentProduct?.id === product?.id
                            ? 'Selected'
                            : 'Select'}
                          {matchedParentProduct &&
                            matchedParentProduct?.platform_product?.id ===
                              product?.platform_product?.id && (
                              <FontAwesomeIcon icon={faArrowRight} className='ms-2' />
                            )}
                        </button>
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <div>
                <h6>No item founds</h6>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <div>
              <strong>
                Total {productVarientsFromMainChannel.ProductVarientCountFromMainChannel} Records{' '}
              </strong>
            </div>
            <Button
              onClick={() => handlePagination(paginationMainData.page - 1)}
              disabled={productVarientsFromMainChannel.previous == null}
            >
              -
            </Button>
            <Button
              onClick={() => handlePagination(paginationMainData.page + 1)}
              disabled={productVarientsFromMainChannel.next == null}
            >
              +
            </Button>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
            {/* <Button variant='primary' onClick={handleLinkProductVariant}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>

        <CSVUploadModal
          show={showUploadModal}
          handleClose={handleCloseUploadModal}
          id={platformModalId}
          platformData={platformData}
          // handleUploadCSV={() => handleUploadCSVFile(csvFile)}
        />

        <CommonAlertModal
          title='The Selected Product will going to be matched with this listing. Do you want to continue?'
          subtitle=''
          onSuccess={() => handleLinkProductVariant()}
          onCancel={() => {
            setOpenConnectConfirmation(false)
            setMatchedParentProduct(null)
          }}
          cancelBtnTitle='Close'
          successBtnTitle='Yes confirm'
          isVisible={openConnectConfirmation}
          isTermsVisible={false}
          setIsVisible={() => {}}
          successDisabled={linkingCompleted}
        />
          <CommonAlertModal
        key={'reffresh data modal'}
        isVisible={showRefreshModal}
        successDisabled={refreshing}
        onCancel={()=>{
          setShowRefreshModal(false)
          setRefreshPlatformId(null)
        }}
        onSuccess={()=>{
          if(refreshPlatformId){
            setRefreshing(true)
            dispatch(refreshPlatformDataAsync({platformId:refreshPlatformId}))
            .then((response)=>{
              successToast(response.payload?.data?.message? response.payload?.data?.message : JSON.stringify(response.payload?.data?.message) )
              setRefreshing(false)
              setRefreshPlatformId(null)
              setShowRefreshModal(false)
            })
          }
        }}
        title='Are you sure ?'
        subtitle='This might take 10-15 minutes to complete'
        isTermsVisible={false}
      />

      <CommonAlertModal
       key={'delist confirmation modal'}
       title='Are you sure?. Want to delist the selected products.'
       subtitle=''
       successBtnTitle={ isDelistIng ? 'Delisting...' : 'Yes Confirm'}
       isTermsVisible={false}
       isVisible={opnDelistModal}
       onSuccess={()=>{
        if(!id){
          return
        }
        console.log(selectedItemForActions);
        setIsDelistIng(true)
        dispatch(delistVariantsAsync({platforrm_id:Number(id),variant_ids:selectedItemForActions}))
        .then((response)=>{
          console.log('delist response', response.payload);
          setSelectedAction('')
          setOpnDelistModal(false);
          setIsDelistIng(false)
        })
        .catch(()=>{
          setIsDelistIng(false)
        })
        
       }}
       onCancel={()=>{setOpnDelistModal(false);setSelectedAction('')}}

      />
      </div>
    </>
  )
}

export default ProductsTable
