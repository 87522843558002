import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import { refreshOauthCallbackAsync } from 'src/redux/actions/authActions'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'


const RefreshHandleOauth:FC = () => {

    const [loading, setLoading] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams();
    const [userId,setUserId]=useState<any>();
    const dispatch = useAppDispatch()
    const {errorToast,successToast,warningToast} = useAppToast()
    const navigate = useNavigate()

    useEffect(() => {
       
        //this will get all query parameters dynamically
        let urlQueryParams = {}
        if(window.location.search){
          let queryItems = window.location.search.slice(1,window.location.search.length).split('&')
          for(let ser of queryItems){
            let keyval = ser.split('=')
            urlQueryParams[keyval[0]] = keyval[1]
          }
        }
       
        setLoading(true)
        dispatch(refreshOauthCallbackAsync({...urlQueryParams}))
        .then((response)=>{
          console.log('refresh token oauth callback res ', response.payload);
          successToast('Token Refreshed')
          navigate('/listing-channels')
          setLoading(false)
        })
        .catch((er)=>{
          console.log('refresh token giving error', er)
          warningToast('Re')
          navigate('/listing-channels')
          setLoading(false)
        })   

    }, [])
    


  return (
   <div className='d-flex align-items-center justify-content-center' >
        {
            loading ? <Loader/> : null
        }
   </div>
  )
}

export default RefreshHandleOauth