import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient, {publicAxiosClient} from '../AxiosClient'

export const loginAsync = createAsyncThunk(
  'auth/loginAsync',
  async function (payload: {email: string; password: string}, toolkit) {
    return await AxiosClient('POST', '/api/token2/', payload, toolkit, [])
  }
)

export const registrationAsync = createAsyncThunk(
  'auth/registrationAsync',
  async function (
    payload: {
      name: string|null
      email: string|null
      password: string
      user_type: 'USER'
      is_superuser: boolean
      is_staff: boolean
      is_active: boolean
      invited_by:number|null
      source:string|null
    
    },
    toolkit
  ) { 
    return await publicAxiosClient('POST', '/user/register', payload, toolkit, [])
  }
)

export const logoutAsync = createAsyncThunk(
  'auth/logoutAsync',
  async function (payload: {email: string; password: string}, toolkit) {
    return await AxiosClient('POST', '/user/logout', payload, toolkit, [])
  }
)

export const verifyRegistrationAsync = createAsyncThunk(
  'auth/verifyRegistrationAsync',
  async function (payload: {activation_token: string}, toolkit) {
    return await AxiosClient('POST', '/user/activate-user', payload, toolkit, [])
  }
)

//forgetpassword

export const requestPasswordForgetAsync = createAsyncThunk(
  'auth/requestPasswordForgetAsync',
  async function (
    payload: {
      email: string
    },
    toolkit
  ) {
    return await publicAxiosClient('POST', '/user/request-forgot-password', payload, toolkit, [])
  }
)



export const verifyForgetPasswordAsync = createAsyncThunk(
  'auth/verifyForgetPasswordAsync',
  async function (payload: {forgot_password_token: string}, toolkit) {
    return await AxiosClient('POST', '/user/verify-forgot-password', payload, toolkit, [])
  }
)
//updateForgottenPasswordAsync

export const updateForgottenPasswordAsync = createAsyncThunk(
  'auth/updateForgottenPasswordAsync',
  async (payload:{forgot_password_token: string;password:string;confirm_password:string}, toolkit) => {
    return await AxiosClient('POST', '/user/update-forgotten-password', payload, toolkit, []);
  }
);

export const checkShopifyUserExistence = createAsyncThunk(
  'auth/checkShopifyUserExistence',
  async (params:{shop:string}, toolkit) => {
    return await AxiosClient('GET', '/shopify-user-existence', [], toolkit, params );
  }
);

export const requestForShopifyOauthUrlAsync = createAsyncThunk(
  'auth/requestForShopifyOauthUrlAsync',
  async (params:{ available_platform:string,client_id:string, redirect_uri:string, scope:string, shop:string}, toolkit) => {
    return await AxiosClient('GET', '/shopify-oauth-url', [], toolkit, params );
  }
);

export const handleShopifyOauthCallbackAsync = createAsyncThunk(
  'auth/handleShopifyOauthCallbackAsync',
  async (payload:{ body: any, params:{code:string} }, toolkit) => {
    return await AxiosClient('POST', '/handle-shopify-oauth-callback', payload.body, toolkit, payload.params );
  }
)

export const handleOauthForConnectPlatformAsync = createAsyncThunk(
  'auth/handleOauthForConnectPlatformAsync',
  async (params:any, toolkit) => {
    return await AxiosClient('GET', '/oauth-url', [], toolkit, params );
  }
)

export const refreshOauthForConnectPlatformAsync = createAsyncThunk(
  'auth/refreshOauthForConnectPlatformAsync',
  async (params:any, toolkit) => {
    return await AxiosClient('GET', '/refresh-oauth-url', [], toolkit, params );
  }
)

export const refreshOauthCallbackAsync = createAsyncThunk(
  'auth/refreshOauthCallbackAsync',
  async (params:any, toolkit) => {
    console.log('params', params)
    return await AxiosClient('GET', '/refresh-token-callback', [], toolkit, params );
  }
)

export const handleOauthCallbackAsync = createAsyncThunk(
  'auth/handleOauthCallbackAsync',
  async (params:any, toolkit) => {
    console.log('params', params)
    return await AxiosClient('GET', '/handle-oauth-callback', [], toolkit, params );
  }
)

export const handleAppInstallationAsync = createAsyncThunk(
  'auth/handleAppInstallationAsync',
  async (params:any, toolkit) => {
    return await AxiosClient('GET', '/handle-app-installation', [], toolkit, params );
  }
)

export const userOauthLoginAsync = createAsyncThunk(
  'auth/userOauthLoginAsync',
  async (payload:any, toolkit) => {
    return await AxiosClient('POST', '/user/oauth/login', payload, toolkit, [] );
  }
)

export const getUserReportsAsync = createAsyncThunk(
  'auth/getUserReportsAsync',
  async (params:any, toolkit) => {
    return await AxiosClient('GET', `/user/user-platforms-report`, [], toolkit, params );
  }
)


